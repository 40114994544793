<template>
  <router-link class="CardButton o-default-body md-elevation-3" :to="route">
    <div>
      <i class="material-icons md-48 arrow-drop-down">{{ icon }}</i>
    </div>
    <div class="card-button__title">
      {{ text }}
    </div>
    <div class="card-button__description">
      {{ description }}
    </div>
  </router-link> <!-- /.CardButton -->
</template>

<script>
export default {
  name: 'CardButton',
  props: {
    route: String,
    icon: String,
    text: String,
    description: String
  }
}
</script>

<style lang="scss" scoped>
.CardButton {
  @include flex-center-xy;
  background: $white;
  color: rgba(0, 0, 0, .7) !important;
  flex-wrap: wrap;
  font-family: $font-family-body-light;
  margin: .8rem;
  min-height: 18rem;
  padding: 2rem;

  &:hover, &:active {
    box-shadow: 0 .3rem .5rem -.1rem rgba(0, 0, 0, .2), 0 .5rem .8rem 0 rgba(0, 0, 0, .14), 0 .1rem 1.4rem 0 rgba(0, 0, 0, .12);
    text-decoration: none;
  }

  &:visited {
    text-decoration: none;
  }

  > div {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
  }

  .material-icons {
    color: rgba(0, 0, 0, .7);
  }

  .card-button__title {
    font-size: 2.4rem;
    line-height: 1;
    padding-bottom: .8rem;
  }

  .card-button__description {
    min-height: 4rem;
  }
}
</style>
