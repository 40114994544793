<template>
  <div class="CardNavList o-default-body md-elevation-3">
    <div class="card-nav-list__title">
      {{ title }}
    </div>
    <div class="card-nav-list__description">
      {{ description }}
    </div>
    <ul class="card-nav-list__ul">
      <li class="card-nav-list__li"
          v-for="(item, index) in items"
          :key="index">
        <router-link :to="item.to">
          {{ item.text }}
        </router-link>
      </li>
    </ul>
  </div> <!-- /.CardNavList -->
</template>

<script>
export default {
  name: 'CardNavList',
  props: {
    title: String,
    description: String,
    items: Array
  }
}
</script>

<style lang="scss" scoped>
.CardNavList {
  background: $white;
  color: rgba(0, 0, 0, .7) !important;
  font-family: $font-family-body-light;
  margin: .8rem;
  padding: 2rem;
  text-align: left;

  .card-nav-list__title {
    font-size: 2.4rem;
    line-height: 1.5;
    padding-bottom: .8rem;
  }

  .card-nav-list__description {
    line-height: 1.5;
    margin-bottom: 1.6rem;
  }

  .card-nav-list__ul {
    list-style-type: none;
  }

  .card-nav-list__li {
    margin-bottom: 1.6rem;

    a {
      font-size: 1.3rem;
      text-decoration: none;

      &:hover, &:active {
        text-decoration: underline;
      }

      &:visited {
        text-decoration: none;
      }
    }
  }
}
</style>